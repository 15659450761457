import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '@app-core/local-storage-service/local-storage.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AuthStateModel, DEFAULT_AUTH_STATE_MODEL } from './auth-state.model';
import { Login, LoginComplete } from './auth.actions';

@State<AuthStateModel>({
  name: 'auth',
  defaults: DEFAULT_AUTH_STATE_MODEL,
})
@Injectable()
export class AuthState {
  @Selector()
  public static userId({ userId }: AuthStateModel): string | null {
    return userId;
  }

  constructor(
    private readonly storageService: LocalStorageService,
    private readonly router: Router
  ) {}
  @Action(LoginComplete)
  public complete(
    { patchState }: StateContext<AuthStateModel>,
    { user }: LoginComplete
  ) {
    patchState({
      userId: user.profile.sub,
    });
  }
  @Action(Login)
  public login({ patchState }: StateContext<AuthStateModel>) {
    this.storageService.set('returnUrl', this.router.url);
  }

}
