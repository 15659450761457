import { Inject, Injectable, Optional } from '@angular/core';
import { LocalStorageService } from '@app-core/local-storage-service/local-storage.service';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { User, UserManager } from 'oidc-client';
import {
  AUTH_CONFIGS,
  AuthConfigs,
  USER_LOADED_TOKEN,
  UserLoadedEvent,
} from '../models';
import { Login, LoginComplete, Logout, Registration } from '../state';
import {IS_FULL_VERSION_TOKEN} from "@app-core/constants";

@Injectable()
export class AuthService {
  private readonly manager: UserManager;

  constructor(
    @Inject(AUTH_CONFIGS) private readonly authConfigs: AuthConfigs,
    @Inject(IS_FULL_VERSION_TOKEN) private isFullToken: boolean,
    private readonly storageService: LocalStorageService,
    @Optional()
    @Inject(USER_LOADED_TOKEN)
    private readonly userLoadedEvent?: UserLoadedEvent
  ) {
    this.manager = new UserManager({
      ...this.authConfigs,
    });
    if (this.userLoadedEvent) {
      this.manager.events.addUserLoaded(this.userLoadedEvent);
    }
  }

  public async signIn<TSource>(stateUrl: TSource, lang:string): Promise<void> {
    this.login();
    return this.manager.signinRedirect({
      state: stateUrl,
      extraQueryParams: {
        isEn: lang !=='ru',
        isGe: !this.isFullToken,
      },
    });
  }

  public async register<T>(url: string) {
    this.registration();
    //FIXME
    const redirectUrl = `${
      this.authConfigs.authority
    }Account/Register?ReturnUrl=${window.location.origin + `?state=${url}`}`;

    window.location.replace(redirectUrl);
  }

  //TODO рефактор
  public isAuth = async (): Promise<boolean> => {
    const user = await this.getUser();
    if (!!user && !user.expired) {
      this.loginComplete(user);
    }

    return !!user && !user.expired;
  };

  public getUser = (): Promise<User | null> => this.manager.getUser();

  public checkPermission = async (value: string): Promise<boolean> => {
    const claims = (await this.getUser())?.profile;
    // if (claims.permission instanceof Array) {
    //   return claims.permission.findIndex((src: any) => src === value) !== -1;
    // }
    // return claims.permission === value;
    return true;
  };

  public completeAuthentication = async <TSource>(): Promise<
    TSource | string
  > => {
    try {
      const user = await this.manager.signinRedirectCallback();
      this.loginComplete(user);
      this.storageService.set('login',true);

      return user.state as TSource;
    } catch (callbackError) {
      return this.storageService.get('returnUrl');
    }

    // const keys = await this.manager.settings.stateStore!.getAllKeys();
    // const states = keys.map((key) => ({
    //   key,
    //   search: this.manager.settings.stateStore!.get(key),
    // }));
    // console.log(states);

    // return '' as any;
  };

  public singOut = async <TSource>(state: TSource, lang:string): Promise<void> => {
    this.logout();
    // console.log(search);
    this.storageService.set('login',false);

    await this.manager.signoutRedirect({
      state,
      extraQueryParams: {
        isEn: lang !=='ru',
        isGe: !this.isFullToken,
      },
    });
  };
  // public singOut = async <TSource>(search?: TSource): Promise<void> => {
  //   await this.manager.signoutRedirect({ search });
  // };

  public singOutComplete = async (state?: string) => {
    const response = await this.manager.signoutRedirectCallback();

    this.manager.clearStaleState();
    return response.state;
  };
  @Dispatch() public login = () => new Login();
  @Dispatch() public loginComplete = (user: User) => new LoginComplete(user);
  @Dispatch() public logout = () => new Logout();
  @Dispatch() public registration = () => new Registration();
}
