import {User} from 'oidc-client';

export class Login {
  public static readonly type = '[Auth] Login';
}
export class LoginComplete {
  public static readonly type = '[Auth] Login complete';
  constructor(public user: User) {}
}
export class Logout {
  public static readonly type = '[Auth] Logout';
}
export class Registration {
  public static readonly type = '[Auth] Registration';
}
